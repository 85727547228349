import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  :root {
    font-size: 62.5%;
  }

  html {
    height: 100vh;
    overflow-x: hidden;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    width: 100%;
    height: 100%;
    font-size: 1.3rem;
    font-family: ${({ theme: { fonts } }) => fonts.ptserif};
    color: ${({ theme: { colors } }) => colors.black};
    background: ${({ theme: { colors } }) => colors.lightgreen};
  }

  h1 {
    font-weight: bold;
    font-family: ${({ theme: { fonts } }) => fonts.montserrat};
    color: ${({ theme: { colors } }) => colors.white};
  }

  h2, h3, h4 {
    text-transform: uppercase;
    font-family: ${({ theme: { fonts } }) => fonts.montserrat};
    color: ${({ theme: { colors } }) => colors.black};
  }

  h1 {
    font-size: clamp(3.5rem, 7vw, 7rem);
  }

  h2 {
    font-size: clamp(3rem, 4.5vw, 4.5rem);
  }

  h3 {
    font-size: 3rem;
  }

  h4 {
    font-size: 2rem;
  }

  a {
    text-decoration: none !important;
    font-family: ${({ theme: { fonts } }) => fonts.montserrat};
  }

  p {
    margin-bottom: 0;
  }
`
export default GlobalStyle
