import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { Props } from 'types/types'

const Container = styled.div<Props>`
  position: relative;
  z-index: 20;
  width: 12rem;
  height: 100%;
  display: flex;
  align-items: center;

  & > a {
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.darkgreen};
  }
`

const Logo = ({ filters }: any) => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          sites {
            title
            linkTo
          }
        }
      }
    }
  `)

  const { title, linkTo } = datas.markdownRemark.frontmatter.sites

  return (
    <Container filters={filters}>
      <AnchorLink to={linkTo} stripHash>
        {title}
      </AnchorLink>
    </Container>
  )
}

export default Logo
