import React from 'react'
import { ThemeProvider } from 'styled-components'

import quote1 from 'assets/images/veggie-quote.jpg'
import quote2 from 'assets/images/veggie-quote2.jpg'
import bg from 'assets/images/pattern.png'
/* Background pattern from Toptal Subtle Patterns */

export const theme = {
  colors: {
    white: '#ECECEC',
    black: '#161616',
    opblack: '#0000009d',
    red: '#dc3546',
    gray: '#DFDFDF',
    lightgray: '#ECECEC',
    darkgray: '#00000091',
    opgray: '#26262620',
    green: '#4dc56d',
    opgreen: '#bbfecdc8',
    lightgreen: '#E7F8E7',
    darkgreen: '#48BF74',
    lightwhite: '#efefef89',
  },
  fonts: {
    ptserif: "'PTSerif', serif",
    montserrat: "'Montserrat', sans-serif",
  },
  backgrounds: {
    pattern: `url(${bg})`,
    google: '#4c8bf5',
    quote1: `url(${quote1})`,
    quote2: `url(${quote2})`,
  },
  filters: {
    green: `invert(61%) sepia(77%) saturate(322%) hue-rotate(101deg) brightness(87%) contrast(89%)`,
  },
}

const Theme = ({ children }: any) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme
