import styled from 'styled-components'
import { Props } from 'types/types'

import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { FlexCenter } from 'styles/_mixins'
import { maxScreen, deviceScreen } from 'styles/_breakpoints'

export const Container = styled.section<Props>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: fit-content;
  background: ${(props) =>
    props.backgrounds ? props.theme.colors.lightgreen : 'transparent'};
  transition: 0.5s ease;
`

export const NavMenu = styled.nav`
  width: 100%;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const NavList = styled.ul<Props>`
  list-style: none;
  width: 50rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${maxScreen.tablet} {
    ${FlexCenter}
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    margin: 0;
    background: ${({ theme: { colors } }) => colors.lightgreen};
    visibility: ${({ click }) => (click ? 'visible' : 'hidden')};
    opacity: ${({ click }) => (click ? '1' : '0')};
    transition: all 0.5s ease;
  }
`

export const NavItems = styled.li`
  text-transform: capitalize;

  &:last-child > a:last-child {
    padding: 1rem 1.8rem;
    font-size: clamp(1.4rem, 2vw, 1.5rem);
    line-height: 1;
    background: ${({ theme: { colors } }) => colors.darkgreen};
    color: ${({ theme: { colors } }) => colors.black};
    border-radius: 0.25rem;

    @media ${maxScreen.tablet} {
      padding: 1.35rem 1.7rem;
    }
  }

  @media ${maxScreen.smart} {
    margin-right: 0;
    padding-bottom: 3rem;

    &:last-child {
      margin-top: 3rem;
      padding-bottom: 0;
    }
  }

  @media ${deviceScreen.smland} {
    margin-right: 0;
    padding-bottom: 1.5rem;

    &:last-child {
      margin-top: 3rem;
      padding-bottom: 0;
    }
  }

  @media ${deviceScreen.tablet} {
    margin-right: 0;
    padding-bottom: 3rem;

    &:last-child {
      margin-top: 3rem;
      padding-bottom: 0;
    }
  }
`

export const NavLink = styled(AnchorLink)<Props>`
  color: ${(props) =>
    props.colors ? props.theme.colors.black : props.theme.colors.lightgreen};

  @media ${maxScreen.tablet} {
    margin: 0;
    font-size: 2rem;
    text-transform: uppercase;
    color: ${({ theme: { colors } }) => colors.black};

    &:focus {
      outline: none;
    }
  }
`
