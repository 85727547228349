import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { Props } from 'types/types'

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`

const Icons = styled.img`
  width: 2rem;
  height: 2rem;
  filter: ${({ theme: { filters } }) => filters.green};
`
const Text = styled.p<Props>`
  text-indent: 1rem;
  margin-bottom: 0;
  font-size: clamp(1.25rem, 4vw, 1.3rem);
  color: ${(props) =>
    props.colors ? props.theme.colors.black : props.theme.colors.white};
  font-family: ${({ theme: { fonts } }) => fonts.montserrat};
`

interface Datas {
  direction: any
  color?: boolean
}

const Info = ({ direction, color }: Datas) => {
  const datas = useStaticQuery(
    graphql`
      query {
        markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
          frontmatter {
            info {
              id
              location {
                text
                icons {
                  src {
                    name
                    publicURL
                  }
                  alt
                }
              }
              time {
                text
                icons {
                  src {
                    name
                    publicURL
                  }
                  alt
                }
              }
              phone {
                text
                icons {
                  src {
                    name
                    publicURL
                  }
                  alt
                }
              }
            }
          }
        }
      }
    `,
  )

  const { location, time, phone } = datas.markdownRemark.frontmatter.info

  return (
    <FlexBox>
      <Content style={{ justifyContent: direction }}>
        <Icons src={location.icons.src.publicURL} alt={location.icons.alt} />
        <Text colors={color}>{location.text}</Text>
      </Content>
      <Content style={{ justifyContent: direction }}>
        <Icons src={time.icons.src.publicURL} alt={time.icons.alt} />
        <Text colors={color}>{time.text}</Text>
      </Content>
      <Content style={{ justifyContent: direction }}>
        <Icons src={phone.icons.src.publicURL} alt={phone.icons.alt} />
        <Text colors={color}>{phone.text}</Text>
      </Content>
    </FlexBox>
  )
}

export default Info
