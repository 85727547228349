import styled, { css } from 'styled-components';
import { Props } from 'types/types';

export const Wrapper = styled.div`
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  height: ${(props: Props) => (props.height ? '100%' : null)};
`;

export const FlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
