import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { Wrapper } from 'styles/_mixins'

import Top from 'components/Top/Top'
import Info from 'components/Info/Info'
import Social from 'components/Social/Social'

const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 7rem 0 5rem;
  background: ${({ theme: { colors } }) => colors.black};
`

const FlexBox = styled.div`
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const Copy = styled.p`
  margin: 5rem auto 0;
  font-size: 1.2rem;
  font-family: ${({ theme: { fonts } }) => fonts.montserrat};
  color: ${({ theme: { colors } }) => colors.white};
`

const Span = styled.span`
  color: ${({ theme: { colors } }) => colors.darkgreen};
`

const Footer = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          footer {
            id
            title
          }
        }
      }
    }
  `)

  const { title } = datas.markdownRemark.frontmatter.footer

  return (
    <Container>
      <Wrapper>
        <FlexBox>
          <Top />
          <Info direction='center' />
          <Social filters={false} direction='row' />
          <Copy>
            <Span>{title}</Span>
            {` © ${new Date().getFullYear()}, Made by JT `}
          </Copy>
        </FlexBox>
      </Wrapper>
    </Container>
  )
}

export default Footer
