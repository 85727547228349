import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useMenuContext } from 'hooks/menu-context'

import { Props } from 'types/types'

import { minScreen } from 'styles/_breakpoints'

const Container = styled.section`
  width: 4rem;
  height: 4rem;

  @media ${minScreen.tablet} {
    visibility: hidden;
    opacity: 0;
  }
`

const Span = styled.span<Props>`
  display: block;
  width: 3rem;
  height: 0.25rem;
  background: ${({ theme: { colors } }) => colors.darkgreen};
  border-radius: 0.25rem;
  transition: all 0.3s ease-in;
`

const Menu = styled.div<Props>`
  position: fixed;
  top: 2rem;
  right: 4.75%;
  padding: 0 !important;
  border-color: transparent !important;
  z-index: 99;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:first-of-type ${Span} {
    margin: 0.6rem auto;
  }

  & ${Span}:nth-child(1) {
    width: ${({ click }) => (click ? '3rem' : '2rem')};
    transform: ${({ click }) => (click ? 'rotate(45deg)' : 'rotate(0)')};
    transform-origin: 20% 0%;
  }

  & ${Span}:nth-child(2) {
    opacity: ${({ click }) => (click ? '0' : '1')};
  }

  & ${Span}:nth-child(3) {
    width: ${({ click }) => (click ? '3rem' : '2rem')};
    transform: ${({ click }) => (click ? 'rotate(-45deg)' : 'rotate(0)')};
    transform-origin: 2% 0%;
  }
`

const Hamburger = ({ filters }: any) => {
  const { clickMenu, setClickMenu } = useMenuContext()

  const handleClick = () => setClickMenu(!clickMenu)

  useEffect(() => {
    const idElement = document.getElementById('menu')

    const handleOutClick = (e: MouseEvent) => {
      if (!idElement?.contains(e.target as HTMLElement)) {
        setClickMenu(false)
      }
    }

    window.addEventListener('click', handleOutClick)
    return () => {
      window.removeEventListener('click', handleOutClick)
    }
  })

  return (
    <Container id='menu'>
      <Menu onClick={handleClick} click={clickMenu}>
        <Span filters={filters}></Span>
        <Span filters={filters}></Span>
        <Span filters={filters}></Span>
      </Menu>
    </Container>
  )
}

export default Hamburger
