import React from 'react'
import styled from 'styled-components'

import Nav from 'components/Header/Nav'
import Footer from 'components/Footer/Footer'

const Container = styled.section`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
const Main = styled.main`
  flex: 1 0 auto;
`

const Layout = ({ backgrounds, children }: any) => {
  return (
    <Container>
      <Nav backgrounds={backgrounds} />
      <Main>{children}</Main>
      <Footer />
    </Container>
  )
}

export default Layout
